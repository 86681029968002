<template>
  <Page
    :back-to="backTo"
    title="SOLICITAÇÃO PARA AGENDAMENTO DE CONSULTA POR VÍDEO/TELEMEDICINA"
    :steps="3"
    :current-step="3"
    :loading="loading"
  >
    <h4 class="navigation-subtitle">Valide as informações</h4>

    <div class="mt-12 text-left font-medium">
      <h1 class="text-gray-500 mb-8">CONFIRME OS DADOS ABAIXO:</h1>

      <h1 class="text-gray-500">NOME DO SOLICITANTE:</h1>
      <p>{{ this.personalInfo.dependent }}</p>

      <h1 class="text-gray-500 mt-6">ESPECIALIDADE:</h1>
      <p>{{ this.speciality }}</p>

      <h1 class="text-gray-500 mt-6">DIA E PERÍODO DE PREFERÊNCIA:</h1>
      <p>{{ this.scheduledTime }}</p>

      <button class="btn btn-blue btn-lg mb-12 mt-16" @click="create">
        SOLICITAR
      </button>
    </div>
  </Page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Page from "../../../components/app/Page";
import ClientErrorException from "../../../exceptions/ClientErrorException";

export default {
  components: { Page },

  props: ["backTo"],

  data() {
    return {
      loading: false
    };
  },

  computed: {
    ...mapGetters("appointments", [
      "personalInfo",
      "speciality",
      "scheduledTimes",
      "scheduledDays",
      "scheduledDayTime"
    ]),

    scheduledTime() {
      if (this.scheduledDayTime) {
        return (
          moment().format("DD/MM/YYYY") + " " + this.scheduledDayTime + "h"
        );
      } else {
        return this.scheduledDays + " - " + this.scheduledTimes;
      }
    }
  },

  mounted() {
    console.log(this.nextRoute);
  },

  methods: {
    ...mapActions("appointments", ["createSchedule"]),

    create() {
      this.loading = true;

      this.createSchedule(true)
        .then(() => {
          this.$router.push({
            name: "appointment.video.success",
            params: {
              message:
                "Em até 3 dias úteis a nossa equipe entrará em contato por telefone. Para acompanhar a sua solicitação, você pode entrar em contato através do telefone 4003 – 1775 para capitais e regiões metropolitanas ou 0800 728 2174 para demais regiões."
            }
          });
        })
        .catch(err => {
          switch (err.constructor) {
            case ClientErrorException:
              this.$toasted.show(
                "Não foi possível processar seu agendamento. Tente mais tarde",
                {
                  position: "bottom-center",
                  type: "error",
                  duration: 2500,
                  fullWidth: true
                }
              );
              console.error(err);
              break;
            default:
              this.$toasted.show(
                "Houve uma instabilidade ao receber o resultado do servidor. Tente mais tarde . Se o problema persistir, você também pode entrar em contato pelo telefone 4003-1775 para capitais e regiões metropolitanas ou 0800 728 2174 para demais regiões.",
                {
                  position: "bottom-center",
                  type: "error",
                  duration: 2500,
                  fullWidth: true
                }
              );
              console.error(err);
              break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
